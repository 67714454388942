<label>
  <p class="secondary-label">How it translates to their language!</p>
  <textarea class="form-textarea" [(ngModel)]="result"></textarea>
</label>
<br />
<div class="grid-5">
  <recog [voice]="googleObj.voice1" [(content)]="googleObj.result"></recog>
  <synth [voice]="googleObj.voice1" [content]="googleObj.result"></synth>
  <copy [content]="googleObj.result"></copy>
  <div></div>
  <div>
    <button class="btn" (click)="clear()">
      <trash-svg></trash-svg>
    </button>
  </div>
</div>
