<select class="form-select" [formControl]="primaryLang">
  <option [ngValue]="null" disabled>Your Language</option>
  <option *ngIf="voices.length == 0" [ngValue]="null" disabled>
    Loading...
  </option>
  <ng-container *ngFor="let voice of voices">
    <option [ngValue]="voice">
      {{voice.name.replace("Google ","").replace("Google&nbsp;","")}}
    </option>
  </ng-container>
</select>
