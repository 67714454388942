<label>
  <p class="primary-label">What you want to say...</p>
  <textarea class="form-textarea" [(ngModel)]="q"></textarea>
</label>
<br />
<div class="grid-5">
  <recog [voice]="googleObj.voice0" [(content)]="googleObj.q"></recog>
  <synth [voice]="googleObj.voice0" [content]="googleObj.q"></synth>
  <copy [content]="googleObj.q"></copy>
  <div></div>
  <div>
    <button class="btn" (click)="clear()">
      <trash-svg></trash-svg>
    </button>
  </div>
</div>
