<div class="cards">
  <div [ngClass]="{'card ': true, 'revealed': revealed}">
    <div class="front">
      {{ cardFront }}
    </div>
    <div class="back">
      {{ cardBack }}
    </div>
  </div>
</div>
<br/>
<p class="cards-label">Use this input to practice spelling or pronunciation</p>
<form #f="ngForm" (ngSubmit)="onSubmit(f)">
  <label aria-label="Use this input to practice spelling or pronunciation">
    <input type="text" placeholder={{placeholder}} aria-autocomplete="none" autocomplete="off" name="cards-input" [(ngModel)]="result" />
  </label>
  <synth [voice]="googleObj.voice1" [content]="cardFront"></synth>
  <recog [voice]="googleObj.voice1" [(content)]="result"></recog>
  <button type="submit" class="btn" aria-label="Submit"><send-svg></send-svg></button>
</form>