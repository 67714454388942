<div class="grid3mobile">
  <div class="primarycontainer">
    <primary></primary>
  </div>
  <div class="translatecontainer">
    <trans></trans>
  </div>
  <div class="secondarycontainer">
    <secondary></secondary>
  </div>
</div>
